
/*! `Custom` Bootstrap 4 theme */


//@import "bootstrap";


@import "_variables";
@import "bootstrap";
@import "_bootswatch";

.sidebar .card 
{
    background-color: $tkg-blue !important;
    border-color: #270485 !important;
    @include box-shadow(#270485);
}

body
{
  background-color: $tkg-blue

  ;
 background-repeat: repeat-x;
  color: #e9ecef;
}

.sidebar {
  -ms-flex: 0 0 250px;
  flex: 0 0 250px;

} 

@media (max-width: 900px) {
  .sidebar {
    flex:none;
  } 
}

.modal-backdrop.show {
  opacity: 0.8;
}

.table td
{
  border-width:1px;
}

.modal-content
{
    color: $blue;
    font-size: 1.1em;
}

.modal-content strong
{
    color:$pink;
}

.day-full
{
  background: repeating-linear-gradient(
    45deg,
    #533e6666,
    #533e6666 10px,

    #392b4266 10px,
    #392b4266 20px,

  )
}

.no-keyholder
{
  background: repeating-linear-gradient(
    90deg,
    #4a4a4b66,
    #4a4a4b66 10px,

    #44444466 10px,
    #44444466 20px,

  )
}

.table td.booking-enabled
{
  box-shadow: none;
  background-color: #9c1cf3;
}

.table td.booking-disabled
{
  box-shadow: none;
  background-color: #333 !important;
  background: repeating-linear-gradient(
    45deg,
    #533e6633,
    #533e6633 10px,

    #392b4266 10px,
    #392b4266 20px,

  )
}

// #606dbc,
// #606dbc 10px,
//    #465298 10px,
//    #465298 20px


@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400&display=swap');

body
{
font-family: 'Montserrat', sans-serif;
}

.pink-tooltip .tooltip-inner
{
  background-color: $pink;
}


.home-card
{
  box-shadow: 0 0 19px rgba(110, 66, 193, 0.287),  0 0 22px rgba(110, 66, 193, 0.363), 0 0 1rem rgba(111, 66, 193, 0.3),  0 0 4rem rgba(111, 66, 193, 0.1);
  background-color: #170229c5 !important;
  width:100%;
  margin-bottom:20px; 
} 

.day-popover
{
  left:25px;
  height:400px;
  top: 100px;
  width:230px;
  position: "fixed";
}

.day-popover .popover-arrow
{
  display:none;
}

@media (max-width: 900px) {
  .day-popover {
    display:none;
  } 
}

.bg-dark .btn-close
{
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat
}



.scrolling-table {
  position: relative;
  overflow: auto;

  white-space: nowrap;
}

.sticky-col {
  position: -webkit-sticky;
  position: sticky;
  left: 0px;
  border-width: 1px;
}

.resource-table td, .resource-table th
{
  text-align: center;
}

.resource-table td
{
  min-width: 150px;
}

.booking-table-day-name
{
  display:none;
}

@media only screen and (max-width: 768px) {
  .booking-table tr, .booking-table tr div, .booking-table td {
    display: block;
    width: 100%;
    max-width: none !important;
  }
    
  thead {
      display: none;
    }

    .booking-table-day-name
    {
      display: inline;
    }

}

.guidanceTable
{
  margin:  auto;
  width: 100%;
}

.guidanceTable td
{
  padding: 0px 15px;
  text-align: center;
  border-right: 1px solid #5f1b97;

}